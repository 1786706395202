<template>
  <v-row class="app-invoice-preview">
    <v-col
      cols="12"
      md="9"
    >
      <invoice-editable :data="invoiceData"></invoice-editable>
    </v-col>

    <!-- Right Column: Invoice Action -->
    <v-col
      cols="12"
      md="3"
    >
      <v-card class="mb-6">
        <v-card-text>
          <v-btn
            color="primary"
            class="mb-3"
            block
            @click="isInvoiceSendSidebarActive = true"
          >
            <v-icon
              class="me-2"
              left
            >
              {{ icons.mdiSendOutline }}
            </v-icon>
            <span>Send Invoice</span>
          </v-btn>
          <v-btn
            class="mb-3"
            color="info"
            block
            outlined
            @click="previewInvoice"
          >
            <v-progress-circular
              v-show="loadingPreview"
              :size="20"
              :width="2"
              indeterminate>
            </v-progress-circular>
            <v-icon
              class="me-2"
              left
              v-show="!loadingPreview"
            >
              {{ icons.mdiEyeOutline }}
            </v-icon>
            <span v-show="!loadingPreview">Preview</span>
          </v-btn>
          <v-btn
            class="mb-3"
            color="secondary"
            block
            outlined
            @click="downloadPdf"
          >
            <v-progress-circular
              v-show="loadingDownload"
              :size="20"
              :width="2"
              indeterminate>
            </v-progress-circular>
            <v-icon
              class="me-2"
              left
              v-show="!loadingDownload"
            >
              {{ icons.mdiDownloadOutline }}
            </v-icon>
            <span v-show="!loadingDownload">Download</span>
          </v-btn>
          <v-btn
            class="mb-3"
            color="success"
            block
            @click="updateInvoice"
          >
            Save
          </v-btn>
        </v-card-text>
      </v-card>

      <invoice-sidebar-settings :data="invoiceData"></invoice-sidebar-settings>
    </v-col>

    <!-- invoice send drawer -->
    <invoice-sidebar-send-invoice v-model="isInvoiceSendSidebarActive"></invoice-sidebar-send-invoice>

    <!-- invoice add payment drawer -->
    <invoice-sidebar-add-payment v-model="isAddPaymentSidebarActive"></invoice-sidebar-add-payment>

    <v-dialog
    v-model="isDialogVisible"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    >
      <v-card tile>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="isDialogVisible = false"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-toolbar-title>Invoice preview</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              color="secondary"
              class="my-auto"
              tile
              small
              @click="downloadPdf"
            >
              Download
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <embed
          :src="pdfSrc"
          type="application/pdf"
          width="100%"
          height="1000px"
        />
      </v-card>
    </v-dialog>

  </v-row>
</template>

<script>
import { mdiSendOutline, mdiCurrencyUsd, mdiDownloadOutline, mdiClose, mdiEyeOutline } from '@mdi/js'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import InvoiceEditable from '../InvoiceEditable.vue'
import invoiceStoreModule from '../invoiceStoreModule'
import InvoiceSidebarSendInvoice from '../InvoiceSidebarSendInvoice.vue'
import InvoiceSidebarAddPayment from '../InvoiceSidebarAddPayment.vue'
import InvoiceSidebarSettings from '../InvoiceSidebarSettings.vue'

export default {
  components: {
    InvoiceEditable,
    InvoiceSidebarSendInvoice,
    InvoiceSidebarAddPayment,
    InvoiceSidebarSettings
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    const isInvoiceSendSidebarActive = ref(false)
    const isAddPaymentSidebarActive = ref(false)

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const invoiceData = ref({
      invoiceData: {
        invoiceData: {

        },
        customer: {

        },
        line_items: []
      }
    })

    store
      .dispatch('app-invoice/fetchInvoice', { id: router.currentRoute.params.id })
      .then(response => {
        invoiceData.value = {
          invoiceData: response.data.invoice
        }

      })
      .catch(error => {
        if (error.response.status === 404) {
          invoiceData.value = undefined
        }
      })

    const updateInvoice = () => {
      store
        .dispatch('app-invoice/updateInvoice', invoiceData.value)
        .then(response => {
          store.dispatch("addNotification",{message:"Invoice data were updated.",type: "success"});
        })
        .catch(error => {
          console.log(error)
        })
    }

    const downloadPdf = () => {
      loadingDownload.value = true
      store
        .dispatch('app-invoice/fetchPdf', [router.currentRoute.params.id])
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = "invoice"
          link.click()
          URL.revokeObjectURL(link.href)
          isDialogVisible.value = false
          loadingDownload.value = false
        })
        .catch(error => {
          console.log(error)
        })
    }

    const pdfSrc = ref('')
    const loadingPreview = ref(false)
    const loadingDownload = ref(false)

    const previewInvoice = () => {
      loadingPreview.value = true
      store
        .dispatch('app-invoice/fetchPdf', [router.currentRoute.params.id])
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          pdfSrc.value = URL.createObjectURL(blob)
          isDialogVisible.value = true
          loadingPreview.value = false
        })
        .catch(error => {
          console.log(error)
        })
    }

    const isDialogVisible = ref(false)

    return {
      invoiceData,
      updateInvoice,

      // drawer
      isInvoiceSendSidebarActive,
      isAddPaymentSidebarActive,

      downloadPdf,

      isDialogVisible,
      previewInvoice,
      pdfSrc,
      loadingPreview,
      loadingDownload,

      // Icons
      icons: {
        mdiSendOutline,
        mdiCurrencyUsd,
        mdiDownloadOutline,
        mdiClose,
        mdiEyeOutline
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';
</style>
